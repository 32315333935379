import { Button, Empty, Modal } from "antd";
import "./VideoDisplay.scss";
import {
  changeImageExtNew,
  changeVideoExt,
  formatMoney,
  openNotificationWithIcon,
} from "../../helpers";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

export default function VideoDisplay({ videoSrc, emptyDescription }) {
  return (
    <div className="video-wrapper">
      <div className="video-row">
        {videoSrc?.question && <div>{videoSrc?.question}</div>}
      </div>
      <div className="video-row">
        {videoSrc?.amount && (
          <div className="d-flex j-space-between py-20 px-20">
            <div className="centeredFlex fontSize9rem">
              <p className="">Merchant Previous Limit : </p>
              <p className="fw500"> NA</p>
            </div>
            <div className="centeredFlex fontSize9rem">
              <p className="">Merchant New Limit : </p>{" "}
              <p className="fw500 "> ₦{formatMoney(videoSrc?.amount)}</p>
            </div>
          </div>
        )}
      </div>
      <div className="video-row">
        {videoSrc?.videoUrl ? (
          <div>
            <video
              src={videoSrc?.videoUrl}
              controls={true}
              className="responsive_video"
            />
          </div>
        ) : (
          <div className="video-placeholder">
            <Empty description={emptyDescription} />
          </div>
        )}
      </div>
    </div>
  );
}

export const RenderVideo = ({ videoSrc, emptyDescription, isLoading }) => {
  const convertedVideoExt = changeVideoExt(videoSrc, "webm");

  return (
    <div className="video-wrapper">
      <div className="video-row py-20 px-20 ">
        {videoSrc?.question && videoSrc?.status !== "declined" && (
          <div>
            <span className="fw500">Liveliness Word:</span> {videoSrc?.question}
          </div>
        )}
      </div>
      <div className="video-row">
        {videoSrc?.amount && (
          <div className="d-flex j-space-between py-20 px-20"></div>
        )}
      </div>
      <div className="video-row">
        {(videoSrc?.video_url || videoSrc?.videoUrl) &&
        videoSrc?.status !== "declined" ? (
          <div>
            <video
              src={convertedVideoExt}
              controls={true}
              className="responsive_video"
            />
          </div>
        ) : (
          <div className="video-placeholder">
            {isLoading && <LoadingOutlined style={{ fontSize: "3.5rem" }} />}
            {!isLoading && <Empty description={emptyDescription} />}
          </div>
        )}
      </div>
    </div>
  );
};

export const RenderVideoSmallerWidth = ({
  videoSrc,
  emptyDescription,
  isLoading,
}) => {
  return (
    <div className="video-wrapper-small">
      <div className="video-row px-20 ">
        {videoSrc?.question && videoSrc?.status !== "declined" && (
          <div>
            <span className="fw500">Liveliness Word:</span> {videoSrc?.question}
          </div>
        )}
      </div>
      <div className="video-row">
        {videoSrc?.amount && (
          <div className="d-flex j-space-between py-20 px-20"></div>
        )}
      </div>
      <div className="video-row">
        {videoSrc ? (
          <div>
            <video
              src={videoSrc?.video_url}
              controls={true}
              className="responsive_video"
            />
          </div>
        ) : (
          <div className="video-placeholder">
            {isLoading && <LoadingOutlined style={{ fontSize: "3.5rem" }} />}
            {!isLoading && <Empty description={emptyDescription} />}
          </div>
        )}
      </div>
    </div>
  );
};

export const RenderImageSmallerWidth = ({
  imgSrc,
  emptyDescription,
  isLoading,
}) => {
  let newImage = changeImageExtNew(imgSrc, "png");
  return (
    <div className="video-wrapper-small">
      <div className="video-row">
        {imgSrc ? (
          <div>
            <img
              src={newImage}
              alt="photo_of_merchant_member"
              className="responsive_small_image"
            />
          </div>
        ) : (
          <div className="video-placeholder">
            {isLoading && <LoadingOutlined style={{ fontSize: "3.5rem" }} />}
            {!isLoading && <Empty description={emptyDescription} />}
          </div>
        )}
      </div>
    </div>
  );
};

export const ButtonRow = ({
  className,
  handleVideo,
  vidInfo,
  confirmLoading,
}) => {
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [type, setType] = useState("");

  const handleAccept = () => {
    setType("accept");
    setIsAcceptModalOpen(true);
  };
  const handleReject = () => {
    setType("reject");
    setIsRejectModalOpen(true);
  };

  return (
    <div className={`d-flex full-width j-space-between pt20 ${className}`}>
      <div>
        <Button
          size="large"
          style={{ backgroundColor: "#33D067", color: "white" }}
          onClick={() => handleAccept()}
        >
          Accept Live Video
        </Button>
      </div>
      <div>
        <Button size="large" danger onClick={() => handleReject()}>
          Reject Live Video
        </Button>
      </div>
      <ConfirmModal
        modalState={isAcceptModalOpen}
        stateHandler={setIsAcceptModalOpen}
        type={type}
        handleVideo={handleVideo}
        vidInfo={vidInfo}
        confirmLoading={confirmLoading}
      />
      <RejectModal
        modalState={isRejectModalOpen}
        stateHandler={setIsRejectModalOpen}
        type={type}
        handleVideo={handleVideo}
        vidInfo={vidInfo}
        confirmLoading={confirmLoading}
      />
    </div>
  );
};

export const ConfirmModal = ({
  modalState,
  stateHandler,
  type,
  handleVideo,
  vidInfo,
  confirmLoading,
}) => {
  // console.log(" this is the vid info ", vidInfo);
  const status = type === "accept" ? "approved" : "declined";
  const handleConfirm = async () => {
    const res = await handleVideo(vidInfo?._id, status);
    stateHandler(false);
  };
  return (
    <Modal
      open={modalState}
      onCancel={() => stateHandler(false)}
      maskClosable={false}
      closable={false}
      onOk={() => handleConfirm()}
      confirmLoading={confirmLoading}
    >
      {type === "accept" && <p className="fontSize17 fw600">Confirm Video</p>}
    </Modal>
  );
};

export const RejectModal = ({
  modalState,
  stateHandler,
  type,
  handleVideo,
  vidInfo,
  confirmLoading,
}) => {
  const status = type === "accept" ? "approved" : "declined";
  const [rejectionReason, setRejectionReason] = useState("");

  const handleChange = (e) => {
    setRejectionReason(e.target.value);
  };
  const handleConfirm = async () => {
    if (rejectionReason.length < 1) {
      openNotificationWithIcon(
        "error",
        "Error",
        "Rejection reason is empty, please provide a reson for rejection."
      );
      return;
    }

    const res = await handleVideo(vidInfo?._id, status, rejectionReason);
    stateHandler(false);
    // clear reason
    setRejectionReason("");
  };
  return (
    <Modal
      open={modalState}
      onCancel={() => stateHandler(false)}
      maskClosable={false}
      closable={false}
      onOk={() => handleConfirm()}
      confirmLoading={confirmLoading}
    >
      {type === "reject" && (
        <div>
          <p className="fontSize17 fw600">
            Are you sure that you want to reject this video?
          </p>
          <TextArea
            allowClear
            placeholder="Enter rejection reason"
            onChange={(e) => handleChange(e)}
          />
        </div>
      )}
    </Modal>
  );
};
